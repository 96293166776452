section.page-content .wrapper.checkout {

  &.checkout--steps {
    border: none;

    .checkout-step {

      margin-bottom: 20px;
      border: 1px solid $checkout-step-border-color;

      .checkout-step__header {
        position: relative;
        padding: 20px;
        background-color: $checkout-step-header-color;

        h2 {
          margin-bottom: 0;
        }
      }

      .checkout-step__body {
        padding: 20px;

        #form_address_shipping > .select-address > .select-address__info > ul > li{
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .checkout-step__body--actions {
          overflow: auto;

          a {
            float: right;
          }
        }

        .radio {
          margin: 0;
        }

        #credit-card-form {
          margin-top: 20px;
        }

        .payment-method__credit-card {

          .payment-method__credit-card--selected {
            margin-top: 20px;

            .payment-method__credit-card--details {
              position: relative;
              padding: 10px;
              border: 1px solid #eee;

              .credit-card--full-name {
                margin-left: 50px;
              }

              .credit-card--info {
                float: none;
                margin-left: 50px;

                span {
                  display: inline-block;
                  vertical-align: middle;

                  &.icon {
                    position: absolute;
                    top: 23px;
                    left: 10px;
                    border: none;
                    border-radius: 0px;
                    background-color: transparent;
                    height: 27px;
                    width: 41px;
                    margin-right: 10px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }
                }
              }
            }
          }

          .credit-card-list {
            margin-top: 20px;
          }
        }
      }
    }

    .checkout-stock-error {
      p {
        color: #AA211F;
        font-weight: bold;
        background-color: #F8A8A6;
        padding: 10px 15px;
        border: 1px solid #E99997;
        text-align: left;

        span {
          color: #AA211F;
          font-weight: bold;
          vertical-align: initial;
        }
      }
    }

    .place-order {
      margin-top: 20px;
      text-align: center;

      .btn-custom {
        width: 100%;
        margin-left: 0;

        &[disabled] {
          opacity: 0.5;
        }
      }
    }
  }

  &.checkout--summary {

    .summary {
      // TODO this is repeated code, but doesn't works if removed because current stylesheets structure
      .vintage-alert {
        margin-top: 20px;

        p {
          text-align: left;
          color: #4E5A61;
          font-weight: normal;
          font-size: small;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .place {
        margin-top: 20px;
        text-align: center;
        .btn-custom {
          width: 100%;
          margin-left: 0;
          &[disabled] {
            opacity: 0.5;
          }
        }
      }

      .span--place_order {
        font-weight: bold;
      }
    }
  }
}
@import '~react-redux-toastr/src/styles/index';

button {
  background-color: transparent;
}

body {
  h1, h2, h3, h4, h5, h6, p, a, span, label, input, button {
    -webkit-font-smoothing: antialiased;
  }
  .form-field__text {
    border: 2px solid #D3D9DB;
    color: #4E5A61;
    &::placeholder {
      color: #677889;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
    &:visited {
      text-decoration: none;
    }
  }
  h1, h2, h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #4E5A61;
    line-height: 37px;
    margin: 0px;
    margin-bottom: 30px;
  }
  h2.centered {
    text-align: center;
  }
  br.sm-only {
    display: none;
  }
  header {
    .navbar.top {
      padding: 5px 0;
      margin-bottom: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border: none;
      box-shadow: 0 2px 10px 4px #1640A0;
      .navbar-collapse {
        .nav {
          padding-top: 20px;
          padding-bottom: 20px;
          li {
            padding-left: 20px;
            padding-right: 20px;
            a {
              text-transform: uppercase;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              font-size: 13px;
              color: #4E5A61;
              padding: 0px;
              line-height: 20px;
              span {
                display: inline-block;
                vertical-align: middle;
              }
              span.text {
                padding-top: 2px;
                border-bottom: 2px solid transparent;
              }
              span.icon {
                color: #B5C420;
              }
              span.vline {
                margin-left: 40px;
                margin-right: 20px;
                border-right: 1px solid #D9DFEA;
                height: 12px;
                width: 1px;
              }
              &:hover {
                background: none;
                background-color: transparent;
              }
              &:focus {
                background: none;
                background-color: transparent;
              }
              &:visited {
                background: none;
                background-color: transparent;
              }
              span {
                display: inline-block;
                vertical-align: middle;
              }
              span.icon {
                font-size: 14px;
                margin-right: 10px;
              }
              span.icon.icon-telephone {
                font-size: 20px;
              }
            }
          }
          li {
            a.active {
              color: #B5C420;
              span.text {
                border-bottom: 2px solid #B5C420;
              }
            }
          }
          li.bordered {
            padding-left: 0px;
            padding-right: 0px;
          }
          li.bordered:last-child {
            span.vline {
              margin-right: 0px;
            }
          }
        }
      }
      .navbar-header {
        .navbar-toggle {
          margin-top: 13px;
          border: 1px solid #8c8c8c;
          .icon-bar {
            background-color: #8c8c8c;
          }
        }
        .navbar-brand {
          padding-top: 10px;
          padding-bottom: 10px;
          height: auto;
          span.logo {
            display: block;
            width: 195px;
            height: 36px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }
      }
    }
    .header-middle {
      background-image: linear-gradient(-46deg, #143C96 0%, #5367FA 100%);
      .top {
        padding-top: 9px;
        padding-bottom: 9px;
        ul.menu {
          display: inline-block;
          margin: 0px;
          li {
            line-height: 36px;
            display: inline-block;
            margin-right: 60px;
            a {
              font-family: 'Roboto', sans-serif;
              font-weight: 400;
              font-size: 14px;
              color: #FFF;
              line-height: 36px;
              span {
                vertical-align: middle;
                display: inline-block;
              }
              span.icon {
                font-size: 8px;
              }
            }
          }
          .dropdown {
            display: inline-block;
            button {
              font-family: 'Roboto', sans-serif;
              font-weight: 400;
              font-size: 14px;
              color: #FFF;
              background: none;
              border: none;
              box-shadow: none;
              padding: 0px;
              line-height: 36px;
              span {
                padding-left: 0px;
                font-family: 'Roboto', sans-serif !important;
                font-weight: 400;
                font-size: 14px;
                color: #FFF;
              }
              span:before {
                font-family: 'icomoon';
                position: absolute;
                top: 50%;
                left: 0px;
                margin-top: -7px;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.4);
              }
            }
            .dropdown-menu {
              border-radius: 2px;
              padding: 0px;
              li {
                margin: 0px;
                display: block;
                a {
                  display: block;
                  text-align: left;
                  font-family: 'Roboto', sans-serif;
                  font-weight: 400;
                  font-size: 14px;
                  color: #4E5A61;
                  line-height: 19px;
                  padding: 20px 20px;
                  -webkit-font-smoothing: liased;
                  span {
                    display: inline-block;
                    vertical-align: middle;
                  }
                  span.icon {
                    margin-right: 10px;
                    margin-left: 0px;
                    color: #143C96;
                    font-size: 20px;
                  }
                }
                a:hover {
                  background-color: #F6FAFB;
                }
                &.dropdown-submenu {
                  &.gift-cards {
                    a {
                      text-align: left;
                      font-weight: 700;
                      padding: 0;
                    }
                    span::after {
                      content: "";
                    }
                  }
                }
              }
              li:first-child {
                a {
                  border-top-left-radius: 2px;
                  border-top-right-radius: 2px;
                }
              }
              li:last-child {
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
              }
            }
          }
          li.dropdown:hover {
            cursor: pointer;
            cursor: hand;
          }
          .dropdown {
            .dropdown-menu {
              li.dropdown-submenu {
                span {
                  display: block;
                  text-align: left !important;
                  font-family: 'Roboto', sans-serif;
                  font-weight: 700;
                  font-size: 14px;
                  color: #4E5A61;
                  padding: 10px 15px;
                  line-height: 20px;
                }
                .dropdown-menu {
                  display: none;
                  box-shadow: none;
                  position: absolute;
                  top: 0px;
                  left: 100%;
                  background-color: #FFF;
                  border-radius: 2px;
                  border: 1px solid #D8D8D8;
                  margin-top: 0px;
                  .sub-categories {
                    min-width: 400px;
                    min-height: 212px;
                    padding: 20px;
                    cursor: auto;
                    h6 {
                      text-align: left;
                      font-family: 'Roboto', sans-serif;
                      font-weight: 700;
                      font-size: 13px;
                      color: #A9B811;
                      line-height: 18px;
                      -webkit-font-smoothing: antialiased;
                      margin: 0px;
                      margin-bottom: 10px;
                    }
                    ul {
                      display: inline-block;
                      margin: 0px;
                      padding: 0px;
                      padding-right: 20px;
                      li {
                        line-height: 19px;
                        margin-bottom: 5px;
                        a, a:hover, a:focus {
                          font-family: 'Roboto', sans-serif;
                          font-weight: 400;
                          font-size: 13px;
                          color: #4E5A61;
                          line-height: 19px;
                          padding: 0px !important;
                          background: none;
                          background-color: transparent;
                        }
                        a:after {
                          display: none;
                        }
                      }
                    }
                  }
                  &.toy {
                    .sub-categories {
                      background-position: right center;
                      background-size: contain;
                      background-repeat: no-repeat;
                    }
                  }
                  &.movie {
                    .sub-categories {
                      background-position: right bottom;
                      background-size: 283px;
                      background-repeat: no-repeat;
                    }
                  }
                  &.poster {
                    .sub-categories {
                      width: 540px;
                      height: auto;
                      background-position: right bottom;
                      background-size: 283px;
                      background-repeat: no-repeat;
                    }
                  }
                  &.gaming {
                    .sub-categories {
                      background-position: right bottom;
                      background-size: 283px;
                      background-repeat: no-repeat;
                    }
                  }
                  &.accessory {
                    .sub-categories {
                      background-position: right bottom;
                      background-size: 230px;
                      background-repeat: no-repeat;
                    }
                  }
                  &.music {
                    display: none;
                    .sub-categories {
                      background-position: right bottom;
                      background-size: 230px;
                      background-repeat: no-repeat;
                    }
                  }
                }
                & span:hover {
                  background-color: #F6FAFB;
                }
                & span:hover > .dropdown-menu {
                  display: block;
                }

                span:after {
                  float: right;
                  display: block;
                  font-family: "icomoon";
                  content: "\E906";
                  width: 20px;
                  height: 20px;
                  font-size: 8px;
                  color: #4E5A61;
                  line-height: 20px;
                  text-align: right;
                }
              }
            }
          }
        }
        ul.menu.right {
          float: right;
          text-align: right;
          li {
            margin: 0px;
            line-height: 35px;
            span.vline {
              margin-left: 25px;
              margin-right: 25px;
              border-right: 1px solid #3C5FAE;
              height: 12px;
              width: 1px;
            }
            a {
              span {
                display: inline-block;
                vertical-align: middle;
              }
              span.vline.right {
                margin-right: 0px;
              }
              span.icon {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.4);
                margin-right: 10px;
              }
            }
          }
          .dropdown {
            margin: 0px;
            button {
              span {
                padding-left: 20px;
              }
              span.caret {
                padding-left: 0px;
              }
            }
          }
        }
        form {
          display: inline-block;
          .form-group {
            input.form-control {
              background: #FFFFFF;
              border: 2px solid #D3D9DB;
              border-radius: 2px;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              font-size: 14px;
              color: #4E5A61;
              height: 35px;
              line-height: 35px;
              padding-top: 0px;
              padding-bottom: 0px;
              width: 100%;
              width: 458px;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
            }
          }
        }
        form.header-search-form {
          .form-group {
            .Select {
              .Select-control {
                height: 33px;
                .Select-placeholder {
                  line-height: 33px;
                }
                .Select-input {
                  height: 33px;
                }
                .Select-arrow-zone {
                  display: none;
                }
                .Select-clear-zone {
                  display: none;
                }
              }
            }
            input {
              background: #FFFFFF;
              border: 2px solid #EAEAEA;
              border-radius: 2px;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              font-size: 14px;
              color: #A5A5B2;
              height: 35px;
              line-height: 35px;
              padding-top: 0;
              padding-bottom: 0;
              width: 440px;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
            }
          }
        }
      }
      .bottom {
        hr {
          width: 100%;
          background: none;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.20);
          margin: 0px;
        }
        ul {
          padding: 15px 0px;
          margin: 0px;
          text-align: center;
          li {
            position: relative;
            line-height: 30px;
            display: inline-block;
            padding-left: 16px;
            padding-right: 22px;
            span.point {
              display: block;
              position: absolute;
              right: 0px;
              top: 50%;
              margin-top: -2px;
              height: 4px;
              width: 4px;
              border-radius: 4px;
              background-color: rgba(255, 255, 255, 0.4);
            }
            a {
              font-family: 'Roboto', sans-serif;
              font-weight: 700;
              font-size: 14px;
              color: #FFF;
              span.green {
                color: #D6E24A;
              }
            }
          }
          li:last-child {
            span.point {
              display: none;
            }
          }
        }
      }
    }
  }
  .subtitle {
    h1 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 28px;
      color: #4E5A61;
      line-height: 37px;
      margin: 0px;
      margin-top: 40px;
    }
  }

  section#content {
    padding: 30px 0px;
    .category-box {
      position: relative;
      height: 422px;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
      margin-bottom: 60px;
      .text {
        position: absolute;
        top: 40px;
        left: 35px;
        h3 {
          color: #153D97;
        }
        &.second {
          h3 {
            color: #FFF;
          }
        }
      }
      .image {
        position: absolute;
        left: 35px;
        bottom: -40px;
        height: 287px;
        width: 201px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url('../img/dvd-bg.png');
        &.second {
          background-image: url('../img/dvd-bg2.png');
        }
      }
      &.second {
        background-image: url('../img/category-bg2.jpg');
      }
    }
    .event-box {
      position: relative;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-shadow: 2px 2px 8px 0 rgba(238, 238, 238, 0.50);
      border-radius: 2px;
      height: 216px;
      width: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      span.text {
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: -39px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #143C96;
        line-height: 26px;
      }
      span.image {
        position: absolute;
        top: 50%;
        margin-top: -150px;
        right: -100px;
        display: block;
        height: 300px;
        width: 300px;
        border-radius: 300px;
        background-repeat: no-repeat;
        background-position: -50px;
        background-image: url('../img/category-bg2.jpg');
      }
    }
  }
  section#products {
    padding: 30px 0px;
    .nav.nav-tabs {
      li {
        padding: 0px;
        a {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 14px;
          color: #4E5A61;
          margin: 0px;
          padding: 12px 15px;
          text-align: center;
          &:hover {
            cursor: pointer;
            //background: none;
            //background-color: #FFF;
            //border-color: transparent;
          }
        }
      }
      li.active {
        a {
          color: #B5C420;
        }
        &:hover {
          //border: 1px solid #ddd;
          //border-bottom-color: transparent;
        }
      }
    }
    .tab-content {
      border: 1px solid #DDD;
      border-top: none;
      .tab-pane {

      }
    }
    .product-grid {
      padding: 20px;
      text-align: center;
      /*.product-item{
                padding: 10px;
                display: inline-block;
                height: 300px;
                .photo{
                    img{
                        margin: 0px auto;
                        width: 132px;
                    }
                }
                .info{
                    h6{
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        color: #4E5A61;
                        margin: 13px 0px;
                        line-height: 19px;
                    }
                    .price{
                        font-family: 'Roboto', sans-serif;
                        font-weight: 700;
                        font-size: 13px;
                        color: #4E5A61;
                        line-height: 18px;
                        span{
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 26px;
                        }
                        span.text{
                            margin-right: 5px;
                        }
                        span.value{
                            font-size: 20px;
                        }
                        .left{
                            margin: 0;
                            display: inline-block;
                            span.value{
                                color: #143C96;
                            }
                        }
                        .right{
                            margin-left: 10px;
                            display: inline-block;
                            span.value{
                                color: #A9B811;
                            }
                        }
                    }
                }
            }*/
      .product-item {
        margin-bottom: 30px;
        border: 1px solid #eee;

        .photo {
          img {
            margin: 0px auto;
            width: 132px;
          }
        }
        .info {
          text-align: center;
          h6 {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #4E5A61;
            margin: 13px 0px;
            line-height: 21px;
          }
          p {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 13px;
            color: #4E5A61;
            line-height: 26px;
            text-align: center;
            span {
              display: inline-block;
            }
            span.cond {
              margin-left: 5px;
            }
            span.price {
              font-size: 20px;
            }
            span.price.used {
              color: #143C96;
            }
            span.price.new {
              color: #A9B811;
            }
            span.discount {
              font-size: 14px;
              font-weight: bold;
              color: #1B8816;
            }
          }
          .ova {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 13px;
            color: #4E5A61;
            line-height: 18px;
            text-align: center;
            strong {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  section#newsletter {
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    background-image: url('../img/news-bg.jpg');
    padding-top: 50px;
    padding-bottom: 50px;
    h3 {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 28px;
      color: #143C96;
      line-height: 37px;
      margin: 0px;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #9095A2;
      line-height: 24px;
      margin-bottom: 20px;
    }
    p:last-child {
      margin-bottom: 0px;
    }
    span.btn-custom {
      width: 100%;
      max-width: 340px;
      background: #143C96;
      box-shadow: 0 1px 0 0 #072059;
      border-radius: 100px;
      color: white;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.50);
    }
  }
  section#ad {
    .box {
      position: relative;
      background-image: linear-gradient(-46deg, #143C96 0%, #5367FA 100%);
      border-radius: 2px;
      padding: 60px 30px;
      overflow-y: hidden;
      span.bg {
        display: block;
        position: absolute;
        top: -30px;
        bottom: -30px;
        left: 30px;
        width: 440px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        background-image: url('../img/Gift-card-banner@2x.png');
      }
      span.line {
        display: block;
        height: 3px;
        background-color: #FFF;
        width: 38px;
        margin: 25px auto;
      }
      h3 {
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 28px;
        color: #FFF;
        line-height: 37px;
      }
      p {
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #FFF;
        line-height: 24px;
        margin: 0px;
        margin-top: 25px;
        a {
          span.btn-custom {
            display: inline-block;
            margin: 0px auto;
            height: 50px;
            width: auto;
            padding: 0px 30px;
            background: #DCE84C;
            box-shadow: 0 2px 0 0 #A9B811;
            border-radius: 2px;
            line-height: 50px;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #143C96;
          }
        }
      }
    }
  }
  section#categories {
    padding-top: 50px;
    padding-bottom: 50px;
    h3 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: #B4C320;
      margin: 0px;
      margin-bottom: 20px;
      line-height: 26px;
    }
    h3.centered {
      text-align: center;
    }
    .grid {
      text-align: center;
      .item {
        margin: 10px 10px;
        display: inline-block;
        div.icon {
          display: block;
          width: 200px;
          height: 200px;
          box-shadow: 2px 2px 4px 0 rgba(153, 153, 153, 0.50);
          border-radius: 10px;
          margin: 20px auto;
          text-align: center;
          background-color: #EEE;
          line-height: 212px;
          span {
            line-height: 212px;
            font-size: 86px;
            color: #FFF;
            display: inline-block;
            height: 90px;
            width: 90px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            vertical-align: middle;
          }
          span.movies {
            background-image: url('../img/movies-bg.png');
          }
          span.games {
            background-image: url('../img/games-bg.png');
          }
          span.cable {
            background-image: url('../img/cable-bg.png');
          }
          span.toys {
            background-image: url('../img/toys-bg.png');
          }
          span.posters {
            background-image: url('../img/posters-bg.png');
          }
        }
        h6 {
          text-align: center;
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 20px;
          color: #333;
          line-height: 26px;
        }
      }
      .item.red {
        div.icon {
          background-color: #DC5944;
        }
        h6 {
          color: #DC5944;
        }
      }
      .item.green {
        div.icon {
          background-color: #B5C420;
        }
        h6 {
          color: #B5C420;
        }
      }
      .item.yellow {
        div.icon {
          background-color: #FBB52B;
        }
        h6 {
          color: #FBB52B;
        }
      }
      .item.pink {
        div.icon {
          background-color: #E35476;
        }
        h6 {
          color: #E35476;
        }
      }
      .item.navy {
        div.icon {
          background-color: #2F3847;
        }
        h6 {
          color: #2F3847;
        }
      }
    }
  }
  footer {
    .top {
      span.logo {
        display: block;
        width: 195px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url('../img/logo.png');
        margin-bottom: 20px;
      }
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: #F6FAFB;
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 14px;
        color: #9095A2;
        line-height: 21px;
        margin-bottom: 20px;
        a {
          span {
            font-size: 26px;
            margin-right: 10px;
          }
          span.icon-facebook {
            color: #38599A;
          }
          span.icon-twitter {
            color: #12A2F3;
          }
        }
      }
      h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #9095A2;
        margin: 0px;
        margin-bottom: 20px;
        line-height: 21px;
      }
      ul {
        padding: 0px;
        margin: 0px;
        margin-bottom: 30px;
        li {
          line-height: 26px;
          a {
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            font-size: 14px;
            color: #9095A2;
          }
        }
      }
    }
    .bottom {
      background-image: linear-gradient(-46deg, #143C96 0%, #5367FA 100%);
      padding-top: 20px;
      padding-bottom: 20px;
      p {
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 14px;
        color: #FFF;
        opacity: 0.9;
        line-height: 21px;
        margin: 0px;
      }
    }
  }
}

#faq.panel-group {
  .panel {
    border: none;
    box-shadow: none;
    border-radius: none;
    .panel-heading {
      background-color: #DEE4F2;
      border: none;
      border-radius: 1px;
      box-shadow: none;
      padding: 0;
      .panel-title {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #4E5A61;
        position: relative;
        a {
          padding: 25px;
          display: block;

          span.icon-arrow-collapse {
            position: absolute;
            top: 0px;
            right: 0px;
          }
          span.icon-arrow-collapse::before {
            content: "\e907";
          }
        }
        a.collapsed {
          span.icon-arrow-collapse::before {
            content: "\e901";
          }
        }
      }
    }
    .panel-collapse {
      border: none;
      box-shadow: none;
      .panel-body {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.pagination {
  li {
    a, a:hover, a:focus {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 14px;
      color: #4E5A61;
      background-color: #FFF;
      padding: 10px 25px;
      border: 1px solid #EBEBEB;
    }
  }
  li.active {
    a {
      color: #A9B811;
      background-color: #FFF;
      border: 1px solid #EBEBEB;
    }
  }
}

.loading {
  text-align: center;
}

.empty-message {
  padding: 30px;
  padding-top: 40px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  text-align: center;
  min-height: 437px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  margin-bottom: 30px;
  h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #4E5A61 !important;
    margin: 0px;
    margin-bottom: 15px !important;
    line-height: 37px;
    -webkit-font-smoothing: antialiased;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #4E5A61;
    margin: 0px;
    margin-bottom: 0px !important;
    line-height: 21px;
    -webkit-font-smoothing: antialiased;
    a {
      span.btn-custom {
        display: inline-block;
        line-height: 50px;
        height: 50px;
        margin: 0px auto;
        background: #DCE84C;
        box-shadow: 0 1px 0 0 #A9B811;
        border-radius: 100px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #143C96;
        text-align: center;
        width: 100%;
        max-width: 280px;
      }
    }
  }
  .footer {
    margin-top: 20px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    p {
      text-align: center;
    }
    .bg {
      width: 100%;
      height: 191px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center bottom;
      border-bottom: 2px solid #EBEBEB;
      margin-bottom: 20px;
    }
    .bg.wish {
      background-image: url("../img/heart-bg@2x.png");
    }
    .bg.cart {
      background-image: url("../img/bag-bg@2x.png");
      height: 164px;
    }
    .bg.payments {
      background-image: url("../img/payments-bg@2x.png");
      height: 184px;
    }
    .bg.addresses {
      background-image: url("../img/addresses-bg@2x.png");
      height: 183px;
    }
    .bg.orders {
      background-image: url("../img/orders-bg@2x.png");
      height: 183px;
      background-position: center bottom -2px;
    }
  }
}

.empty-message.thanks {
  height: 506px;
  .bg {
    background-image: url("../img/thanks-bg@2x.png");
    height: 225px;
    background-position: center bottom -2px;
  }
}

.empty-message.search {
  background-image: url("../img/search-bg@2x.png");
  .footer {
    display: none;
  }
}

.empty-message.not-found {
  background-image: url("../img/no-found-bg.png");
  .footer {
    display: none;
  }
}

.modal {
  .modal-dialog {
    width: 90%;
    max-width: 750px;
    margin: 10px auto;
  }
  .modal-content {
    padding: 45px;
  }
  .modal-header {
    position: relative;
    padding: 0px;
    border-bottom: none;
    h4 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 24px;
      color: #4E5A61;
      line-height: 48px;
      margin: 0px;
    }
    .close {
      opacity: 1;
      margin: 0px;
      font-size: 18px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: 0px;
      line-height: 20px;
      font-size: 20px;
      span {
        display: inline-block;
        background-color: #C9D0E0;
        color: #FFF;
        height: 22px;
        width: 22px;
        border-radius: 22px;
        text-align: center;
        text-shadow: none;
        font-size: 18px;
      }
      span.sr-only {
        display: none;
      }
    }
  }
  .modal-body {
    padding: 0;
    padding-top: 40px;
    .align-right {
      text-align: right;
    }
    h2 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 20px;
      color: #A9B811;
      line-height: 26px;
      margin: 0px;
      margin-bottom: 15px;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      span.circle {
        width: 22px;
        height: 22px;
        border-radius: 22px;
        border: 3px solid #A9B811;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: #A9B811;
        line-height: 15px;
        text-align: center;
        margin-right: 10px;
      }
    }
    form {
      .form-group {
        label {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 16px;
          color: #4E5A61;
          margin-bottom: 10px;
        }
        input {
          background: none;
          background-color: #FFF;
          border: 2px solid #EBEBEB;
          height: 50px;
          border-radius: 2px;
          box-shadow: none;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #999;
        }
        select.form-control {
          background-color: #FFF;
          border: 2px solid #E8E8E8;
          border-radius: 2px;
          padding: 0px 15px;
          padding-right: 40px;
          font-family: 'Arimo', sans-serif;
          font-weight: 700;
          font-size: 14px;
          color: #8F979D;
          line-height: 16px;
          margin-bottom: 0px;
          height: 50px;
          -webkit-appearance: none;
          background: url('../img/select-bg.png') no-repeat;
          background-position: right 15px center;
          background-size: 8px;
          -webkit-font-smoothing: antialiased;
        }
      }
      input[type="submit"] {
        height: 50px;
        line-height: 50px;
        background: none;
        border: none;
        background-color: #DCE84C;
        box-shadow: 0 1px 0 0 #A9B811;
        border-radius: 100px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: #143C96;
        padding: 0 30px;
        -webkit-font-smoothing: antialiased;
        min-width: 120px;
        &:disabled {
          opacity: 0.7 !important;
        }
      }

      button {
        height: 50px;
        line-height: 50px;
        background: none;
        border: none;
        background-color: #DCE84C;
        box-shadow: 0 1px 0 0 #A9B811;
        border-radius: 100px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: #143C96;
        padding: 0 30px;
        -webkit-font-smoothing: antialiased;
        min-width: 120px;
        &:disabled {
          opacity: 0.7 !important;
        }
      }
      .btn-custom {
        display: inline-block;
        height: 50px;
        padding: 0px 30px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #FFF;
        background-color: #143C96;
        border-radius: 100px;
        line-height: 50px;
        box-shadow: none;
        &:disabled {
          opacity: 0.7 !important;
        }
      }
      .cancel {
        background: #4E5A61;
        box-shadow: 0 1px 0 0 #9A9A93;
        margin-right: 10px;
        color: #FFF;
      }
      .custom-checkbox {
        margin-right: 10px;
      }
    }
    a.print-button {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      background: none;
      border: none;
      background-color: #DCE84C;
      box-shadow: 0 1px 0 0 #A9B811;
      border-radius: 100px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #143C96;
      padding: 0px 30px;
      -webkit-font-smoothing: antialiased;
      min-width: 120px;
      text-align: center;
      margin-top: 30px;
    }
  }
  .modal-footer {
    padding: 0px;
    border-top: none;
    text-align: right;
    .btn-custom {
      display: inline-block;
      height: 50px;
      padding: 0px 30px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 14px;
      color: #FFF;
      background-color: #143C96;
      border-radius: 100px;
      line-height: 50px;
      &:disabled {
        opacity: 0.7 !important;
      }
    }
    .btn-custom.cancel {
      background: #B9B9B6;
      box-shadow: 0 1px 0 0 #9A9A93;
      margin-right: 10px;
    }
    .btn-custom.save {
      background: #DCE84C;
      box-shadow: 0 1px 0 0 #A9B811;
      color: #143C96;
    }
  }
}

.invoice {
  .header {
    text-align: right;
    h6 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #4E5A61;
      line-height: 24px;
      margin: 0px;
    }
    span.logo {
      display: block;
      float: left;
      width: 134px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url('../img/logo@2x.png');
    }
  }
  .body {
    h6 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #A9B811;
      line-height: 21px;
      margin: 20px 0px;
    }
    h6.centered {
      text-align: center;
    }
    .details {
      display: inline-block;
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        span.value {
          display: block;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
    .details.right {
      float: right;
    }
    .field {
      padding: 15px 20px;
      margin-bottom: 20px;
      background-color: #F6FAFB;
      border-radius: 2px;
      border: 1px solid #EBEBEB;
      h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #4E5A61;
        margin: 0px;
        line-height: 21px;
        span {
          display: inline-block;
          line-height: 21px;
          float: right;
          font-weight: 400;

        }
      }
    }
    .address {
      h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #4E5A61;
        line-height: 21px;
        margin: 0px;
        margin-bottom: 20px;
      }
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #4E5A61;
        line-height: 26px;
        span.value {
          display: block;
        }
      }
    }
    .wrapper {
      border-bottom: 1px solid #EBEBEB;
      padding-bottom: 15px;
      h6 {
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 13px;
        color: #4E5A61;
        margin: 0px;
      }
      h6.right {
        float: right;
      }
    }
    .item {
      position: relative;
      padding: 15px 0px;
      border-bottom: 1px solid #EBEBEB;
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        margin: 0px;
        line-height: 30px;
        span.cond {
          font-weight: 700;
        }
        span.qty {
          font-weight: 300;
          margin-left: 90px;
        }
      }
      .photo {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
      }
      .content {
        display: inline-block;
        vertical-align: middle;
      }
      .price {
        position: absolute;
        bottom: 16px;
        right: 0;
      }
      span.right {
        float: right;
      }
    }
    .order-values {
      .item {
        margin-bottom: 0px;
      }
    }
  }
}

.cancel-items {
  .modal-header {
    padding-bottom: 40px;
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;
      margin: 0px;
      margin-top: 40px;
      line-height: 24px;
    }
  }
  .modal-body {
    padding: 0px;
    form {
      .form-group {
        width: 100%;
        margin-bottom: 20px;
        label {
          width: 40%;
          font-size: 14px;
          font-weight: 400;
          color: #4E5A61;
          text-align: right;
          vertical-align: top;
          padding-right: 20px;
        }
        .form-control {
          width: 59%;
        }
      }
    }
    .table {
      margin-bottom: 20px;
      thead {
        tr {
          th {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 13px;
            color: #4E5A61;
            border-bottom: 1px solid #EBEBEB;
            padding: 15px 0px;
            -webkit-font-smoothing: antialiased;
          }
          th.right {
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          td {
            border: none;
            border-bottom: 1px solid #EBEBEB;
            vertical-align: middle;
            padding: 15px 0px;
            .item {
              padding: 0px;
              border: none;
              .price {
                bottom: 0px;
              }
            }
            .custom-checkbox {
              margin: 0px;
              input {
                margin: 0px;
              }
              label {
                margin: 0px;
              }
            }
          }
          td.center {
            text-align: center;
          }
        }
      }
    }
  }
}

.thanks {
  .modal-body {
    padding-top: 20px;
    h1 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 36px;
      color: #4E5A61;
      line-height: 48px;
      margin: 0px;
      margin-bottom: 30px;
      text-align: center;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;
      line-height: 24px;
      margin: 0px;
      margin-bottom: 30px;
      text-align: center;
      .icon {
        color: #143C96;
        font-size: 42px;
      }
      a {
        span.btn-custom {
          display: inline-block;
          line-height: 50px;
          height: 50px;
          width: 90%;
          margin: 0px auto;
          background: #DCE84C;
          box-shadow: 0 1px 0 0 #A9B811;
          border-radius: 100px;
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 14px;
          color: #143C96;
          text-align: center;
          max-width: 214px;
        }
      }
    }
    p:last-child {
      margin-bottom: 0px;
    }
  }
}

section.title {
  padding-top: 40px;
  h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #143C96;
    line-height: 37px;
    margin: 0px;
  }
}

section.page-content {
  padding: 40px 0px;
  .wrapper.locations {
    min-height: 573px;
    padding: 0px;
    .left {
      padding-right: 0px;
      .finder {
        p {
          text-align: center;
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 16px;
          color: #4E5A61;
          line-height: 21px;
          margin-bottom: 10px;
        }
        form.form-inline {
          padding: 20px;
          .custom-input {
            display: table;
            width: 100%;
            border-radius: 100px;
            border: 1px solid #EAEAEA;
            padding: 3px;
            .input {
              width: 82%;
              display: table-cell;
              input {
                border-radius: 100px;
                border: none;
                height: 45px;
                padding-left: 17px;
                padding-right: 17px;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 13px;
                color: #A7B6BE;
                width: 100%;
                box-shadow: none;
              }
            }
            .submit {
              display: table-cell;
              text-align: right;
              width: 18%;
            }
            span.vline {
              display: inline-block;
              vertical-align: middle;
              border-right: 1px solid #EAEAEA;
              height: 16px;
            }
            button.btn {
              background-color: #FFF;
              height: 45px;
              border: none;
              border-top-right-radius: 100px;
              border-bottom-right-radius: 100px;
              padding: 0px 10px;
              line-height: 45px;
              span {
                display: inline-block;
                vertical-align: middle;
                color: #C5C9CB;
                font-size: 16px;
              }
            }
          }
          .location__wrapper-button {
            margin-top: 20px;
            .form-group {
              display: block;
              button.btn-custom {
                width: 100%;
                margin-left: 0;
                padding: 0 20px;
              }
              .form-field {
                display: block;
                select.location-select-mile {
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
        .results {
          height: 487px;
          overflow-y: auto;
          .panel-group {
            margin-bottom: 0px;
            .panel {
              border: none;
              box-shadow: none;
              border-radius: none;
              margin: 0px;
              .panel-heading {
                cursor: pointer;
                position: relative;
                border: none;
                border-radius: 0px;
                padding: 0px;
                box-shadow: none;
                .panel-title {
                  font-family: 'Roboto', sans-serif;
                  font-weight: 400;
                  font-size: 16px;
                  color: #4E5A61;
                  position: relative;
                  a {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 700;
                    font-size: 14px;
                    color: #4E5A61;
                    -webkit-font-smoothing: antialiased;
                    line-height: 20px;
                    background-color: #F6FAFB;
                    display: block;
                    padding: 20px;
                    span.icon-arrow-collapse {

                    }
                    span.icon-arrow-collapse::before {
                      font-size: 12px;
                      content: "\e907";
                    }
                  }
                  a.collapsed {
                    background-color: #FFF;
                    border-bottom: 1px solid #EAEAEA;
                  }
                  a:after {
                    font-family: 'icomoon';
                    font-size: 12px;
                    content: "\e907";
                    float: right;
                  }
                  //a.collapsed:after {
                  //  content: "\e901";
                  //}
                }
              }
              .panel-heading {
                background-color: #F6FAFB;
              }
              .panel-collapse {
                border: none;
                box-shadow: none;
                .panel-body {
                  padding: 20px;
                  //padding-top: 0px;
                  border: none;
                  box-shadow: none;
                  background-color: #F6FAFB;
                  p {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    color: #4E5A61;
                    text-align: left;
                    line-height: 19px;
                    -webkit-font-smoothing: antialiased;
                    strong {
                      font-weight: 700;
                    }
                    span.email {
                      font-weight: 400;
                      color: #A9B811;
                    }
                    a {
                      font-size: 14px;
                      color: #B5C420;
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
            .panel:last-child {
              .panel-heading {
                a.collapsed {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
    .right {
      height: 611px;
      #map {
        position: absolute;
        left: 0px;
        right: 15px;
        top: 0px;
        bottom: 0px;
        background-color: #EBEBEB;
        .gm-style-iw {
          text-align: center !important;
        }
        h6 {
          text-align: center !important;
          margin-bottom: 10px !important;
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 12px;
          color: #4E5A61;
          -webkit-font-smoothing: antialiased;
        }
        p {
          text-align: center !important;
          margin-bottom: 10px !important;
          line-height: 20px !important;
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 12px;
          color: #4E5A61;
          -webkit-font-smoothing: antialiased;
          a {
            color: #A9B811;
            span {
              display: inline-block;
              vertical-align: middle;
            }
            span.icon-Pin {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .wrapper.checkout {
    min-height: 0px;
    padding: 0px;
    form {
      .form-group {
        input[type="submit"] {
          height: 35px;
          line-height: 35px;
          padding: 0px 20px;
        }
      }
      .radio {
        label {
          width: 100%;
        }
      }
    }
    .summary {
      padding: 15px;
      padding-top: 30px;
      h4 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #4E5A61;
        line-height: 37px;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 20px;
        a {
          float: right;
          font-family: 'Roboto', sans-serif;
          //font-weight: 400;
          font-size: 14px;
          color: #143C96;
        }
      }
      .item {
        .photo {
          border-radius: 2px;
          display: inline-block;
          vertical-align: middle;
          width: 62px;
          padding-right: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          img {
            width: 100%;
          }
        }
        .info {
          display: inline-block;
          vertical-align: middle;
          p {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #4E5A61;
            line-height: 24px;
            text-align: left;
            margin: 0px;
            span {
              display: inline-block;
              vertical-align: middle;
              font-family: 'Roboto', sans-serif;
              font-weight: 700;
              font-size: 13px;
              color: #4E5A61;
            }
            span.cond {
              margin-left: 5px;
            }
            span.price {
              font-family: 'Roboto', sans-serif;
              font-weight: 700;
              font-size: 18px;
              color: #4E5A61;
              margin: 0px;
              &.new {
                color: #143C96;
              }
              &.used {
                color: #143C96;
              }
            }
            span.qty {
              font-weight: 400;
              margin-left: 5px;
            }
          }
          p:last-child {
            margin-bottom: 0px;
          }
        }
      }
      table {
        margin-bottom: 0px;
        tbody {
          tr {
            td {
              border-top: none;
              font-family: 'Roboto', sans-serif;
              font-weight: 400;
              font-size: 14px;
              color: #4E5A61;
              -webkit-font-smoothing: antialiased;
              padding: 0px;
              padding-bottom: 20px;
            }
            td.right {
              text-align: right;
            }
          }
          tr.total {
            border-top: 1px solid #EBEBEB;
            td {
              font-weight: 700;
              padding-top: 20px;
              padding-bottom: 0px;
            }
          }
        }
      }
      p {
        a {
          float: none;
        }
      }
    }
    h2 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 20px;
      //color: #A9B811;
      color: #4E5A61;
      line-height: 35px;
      margin: 0px;
      margin-bottom: 15px;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      span.circle {
        width: 22px;
        height: 22px;
        border-radius: 22px;
        //border: 3px solid #A9B811;
        border: 3px solid #4E5A61;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 12px;
        //color: #A9B811;
        color: #4E5A61;
        line-height: 15px;
        text-align: center;
        margin-right: 10px;
      }
    }
    .options {
      position: absolute;
      right: 30px;
      top: 20px;
      form {
        .form-group {
          margin-bottom: 0px;
          select.form-control {
            background-color: #FFF;
            border: 1px solid #E8E8E8;
            border-radius: 2px;
            padding: 0px 15px;
            padding-right: 40px;
            font-family: 'Arimo', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #8F979D;
            line-height: 16px;
            margin-bottom: 0px;
            height: 36px;
            -webkit-appearance: none;
            background: url('../img/select-bg.png') no-repeat;
            background-position: right 15px center;
            background-size: 8px;
            -webkit-font-smoothing: antialiased;
          }
        }
      }
      .btn-custom {
        display: inline-block;
        height: 35px;
        padding: 10px 20px;
        line-height: 16px;
        background: #DCE84C;
        box-shadow: 0 1px 0 0 #A9B811;
        border-radius: 100px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #143C96;
        span {
          display: inline-block;
          vertical-align: middle;
        }
        span.icon {
          color: #143C96;
          font-size: 8px;
          font-weight: 700;
          margin-right: 3px;
        }
      }
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #4E5A61;
      margin: 0px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      span {
        font-weight: 400;
        display: inline-block;
        vertical-align: middle;
      }
      span.icon {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        height: 27px;
        width: 41px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      span.paypal {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        height: 18px;
        width: 68px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/paypal-bg.png');
      }
      span.icon.visa {
        background-image: url('../img/credit-card.png');
      }
      span.icon.amex {
        background-image: url('../img/credit-card-amex.png');
      }
      span.icon.mastercard {
        background-image: url('../img/credit-card-mastercard.png');
      }
      span.icon.discover {
        background-image: url('../img/credit-card-discover.png');
      }
      span.bold {
        font-weight: 700;
      }
      span.exp {
        float: right;
      }
      span.price {
        margin-left: 15px;
      }
      a {
        float: right;
        font-size: 14px;
        font-weight: 700;
        color: #A9B811;
        span {
          font-weight: 700;
        }
      }
    }
    .shipping-address, .shipping-type {
      padding: 30px;
      border-bottom: 1px solid #EBEBEB;
      position: relative;
    }
    .payment-method {
      padding: 30px;
      position: relative;
    }
  }
  .wrapper.addresses {
    padding-bottom: 30px;
    .row {
      > div {
        margin-bottom: 30px;
      }

      > div:last-child {
        margin-bottom: 0
      }
    }

    .address-item {
      padding: 15px;
      border: 1px solid #EBEBEB;
      border-radius: 2px;
      position: relative;
      &.address-item--default {
        border: 1px solid #143C96;
      }
      .address-item__content {
        line-height: 20px;
        .row {
          > div {
            margin-bottom: 0;
            > p{
              font-weight: bold;
              margin-bottom: 16px;
            }
          }

        }

        .address-item__by-default {
          p[class^="address-item__by-default__"] {
            color: #143C96;
            font-weight: 500;
            text-transform: uppercase;
            text-align: right;
            margin-bottom: 2px;
          }
        }

        h3 {
          font-weight: 900;
        }

        .address-item__content__address {
          p {
            margin-bottom: 10px;
          }
        }
      }
      .address-item__actions {
        > div[class^="address-item__actions__"] {
          display: inline-block;
          padding: 5px 15px 5px 0;
          i[class~="fa"] {
            margin-right: 5px;
          }
        }

        .address-item__actions__hover {
          cursor: pointer;
        }

        > div[class^="address-item__actions__"]:nth-child(2) {
          &.address-item__actions__delete--bar-right {
            border-right: 2px solid #EBEBEB;
          }
        }

        > div[class^="address-item__actions__"]:last-child {
          padding-left: 15px;
        }

        .address-item__actions__status {
          .address-item__actions__status__default {
            color: #143C96;
            font-weight: 900;
          }

          .address-item__actions__status__set-as-default {
            .dropdown.btn-group {
              margin-left: 5px;
            }
          }
        }
      }

      .right {
        text-align: right;
      }
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        line-height: 19px;
        -webkit-font-smoothing: antialiased;
        span {
          font-weight: 700;
        }
      }
      .extra {
        h6 {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #4E5A61;
          margin: 0px;
          margin-bottom: 10px;
        }
        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #4E5A61;
          line-height: 27px;
          margin-bottom: 0px;
        }
      }
      .options {
        display: inline-block;
        a {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #4E5A61;
          line-height: 19px;
          span {
            display: inline-block;
            vertical-align: middle;
            margin-right: 30px;
          }
          span.red {
            color: #AE1A20;
          }
          span.green {
            color: #A9B811;
          }
        }
      }
      .dropdown {
        display: inline-block;
        button {
          padding: 0px;
          background: none;
          border: none;
          line-height: 19px;
          span {
            font-size: 8px;
          }
        }
      }
    }
    .address-item:last-child {
      margin-bottom: 0;
    }
  }
  .wrapper.payments {
    padding-bottom: 30px;
    .row {
      > div {
        margin-bottom: 30px;
      }

      > div:last-child {
        margin-bottom: 0
      }
    }

    .payment-item {
      padding: 15px;
      border: 1px solid #EBEBEB;
      border-radius: 2px;
      &.payment-item--default {
        border: 1px solid #143C96;
      }

      p[class^="payment-item__credit-card"] {
        margin-bottom: 10px;
      }

      .payment-item__actions {
        > div[class^="payment-item__actions__"] {
          display: inline-block;
          padding: 5px 15px 5px 0;
          i[class~="fa"] {
            margin-right: 5px;
          }
        }

        .payment-item__actions__hover {
          cursor: pointer;
        }

        > div[class^="payment-item__actions__"]:nth-child(2) {
          border-right: 2px solid #EBEBEB;
        }

        > div[class^="payment-item__actions__"]:last-child {
          padding-left: 15px;
        }

        .payment-item__actions__status {
          .payment-item__actions__status__default {
            color: #143C96;
            font-weight: 900;
          }
        }
      }

      //padding-bottom: 30px;
      //margin-bottom: 30px;
      //border-bottom: 1px solid #E8E8E8;
      //.right {
      //  text-align: right;
      //}
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        line-height: 19px;
        -webkit-font-smoothing: antialiased;
        span {
          display: inline-block;
          vertical-align: middle;
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 14px;
          color: #4E5A61;
          line-height: 27px;
        }
        span.number {
          font-weight: 400;
        }
        span.exp {
          font-weight: 400;
          margin-right: 5px;
        }
        span.icon {
          border: none;
          border-radius: 0px;
          background-color: transparent;
          height: 27px;
          width: 41px;
          margin-right: 10px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
        span.icon.visa {
          background-image: url('../img/credit-card.png');
        }
        span.icon.amex {
          background-image: url('../img/credit-card-amex.png');
        }
        span.icon.mastercard {
          background-image: url('../img/credit-card-mastercard.png');
        }
        span.icon.discover {
          background-image: url('../img/credit-card-discover.png');
        }
      }
      .default-status {
        font-weight: 700;
        color: #999999;

        &.default-status--set-as-default {
          color: #143C96;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      .extra {
        h6 {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #4E5A61;
          margin: 0px;
          margin-bottom: 10px;
        }
        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #4E5A61;
          line-height: 27px;
          margin-bottom: 0px;
        }
      }
      .options {
        display: inline-block;
        a {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #4E5A61;
          line-height: 19px;
          span {
            display: inline-block;
            vertical-align: middle;
            margin-right: 30px;
          }
          span.red {
            color: #AE1A20;
          }
          span.green {
            color: #A9B811;
          }
        }
      }
      .dropdown {
        display: inline-block;
        button {
          padding: 0px;
          background: none;
          border: none;
          line-height: 19px;
          span {
            font-size: 8px;
          }
        }
      }
    }
    //.payment-item:last-child {
    //  border-bottom: none;
    //  margin-bottom: 0px;
    //  padding-bottom: 0px;
    //}
  }
  .wrapper.wishlist {
    padding-bottom: 30px;
    .wish-item {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #E8E8E8;
      .product {
        display: inline-block;
        vertical-align: middle;
        .photo {
          border-radius: 2px;
          float: left;
          display: inline-block;
          vertical-align: middle;
          width: 72px;
          padding-right: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          img {
            width: 100%;
          }
        }
        .info {
          display: block;
          vertical-align: middle;
          margin-left: 82px;
          padding-top: 5px;
          p {
            margin: 0px;
            margin-bottom: 5px;
            line-height: 18px;
          }
          .price {
            display: block;
            line-height: 20px;
            margin-bottom: 5px;
            span {
              display: inline-block;
              vertical-align: middle;
              font-family: 'Roboto', sans-serif;
              font-weight: 700;
              font-size: 13px;
              color: #4E5A61;
              margin-right: 10px;
            }
            span.used {
              color: #143C96;
              font-size: 20px;
            }
            span.new {
              color: #A9B811;
              font-size: 20px;
            }
          }
          .box {
            p {
              color: #1B8816;
              font-family: 'Roboto', sans-serif;
              font-weight: 700;
              font-size: 13px;
              text-align: left;
              margin: 0px;
              span {
                display: inline-block;
                vertical-align: middle;
                color: #1B8816;
                font-weight: 700;
                font-size: 13px;
              }
              span.icon {
                border-radius: 0px;
                background: none;
                border: none;
                height: auto;
                width: auto;
                margin-right: 5px;
                font-size: 14px;
                height: 14px;
                line-height: 14px;
              }
              span.vline {
                display: none;
              }
              span.icon.blue {
                color: #143C96;
              }
              span.discount {
                color: #143C96;
                font-weight: 400;
                font-size: 14px;
                text-align: left;
                line-height: 18px;
                strong {
                  font-weight: 700;
                }
              }
            }
          }
          .ova {
            display: none;
            p {
              font-family: 'Roboto', sans-serif;
              font-weight: 400;
              font-size: 16px;
              color: #4E5A61;
              a {
                font-weight: 700;
                color: #143C96;
                text-decoration: underline;
              }
              span {
                font-weight: 400;
                font-size: 16px;
                color: #4E5A61;
              }
              strong {
                font-weight: 700;
                color: #A9B811;
              }
            }
          }
        }
      }
      .right {
        float: none;
        height: 72px;
        line-height: 72px;
        .input {
          display: inline-block;
          vertical-align: middle;
          line-height: 35px;
          label {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #4E5A61;
            margin: 0px;
            margin-right: 20px;
            -webkit-font-smoothing: antialiased;
          }
          input[type="number"] {
            height: 35px;
            line-height: 35px;
            width: 120px;
            border-radius: 100px;
            border: 1px solid #EBEBEB;
            padding: 0px 20px;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 18px;
            color: #4E5A61;
            text-align: center;
          }
        }
        .options {
          display: inline-block;
          vertical-align: middle;
          line-height: 35px;
          button {
            margin-left: 40px;
          }
          a {
            display: inline-block;
            vertical-align: middle;
            line-height: 35px;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #A9B811;
            -webkit-font-smoothing: antialiased;
            margin-left: 40px;
            span {
              display: inline-block;
              vertical-align: middle;
              font-size: 14px;
              line-height: 35px;
            }
            span.icon-plus {
              font-size: 7px;
            }
            span.icon-rubbish {
              font-size: 18px;
              color: #4E5A61;
            }
          }
        }
      }
    }
    .wish-item:last-child {
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
  .wrapper {
    position: relative;
    padding: 30px;
    padding-bottom: 110px;
    border: 1px solid #EBEBEB;
    min-height: 437px;
    .filters {
      position: absolute;
      right: 30px;
      top: 30px;
      form {
        .form-group {
          margin-bottom: 0px;
          select.form-control {
            background-color: #FFF;
            border: 1px solid #E8E8E8;
            border-radius: 2px;
            padding: 0px 15px;
            padding-right: 40px;
            font-family: 'Arimo', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #8F979D;
            line-height: 16px;
            margin-bottom: 0px;
            height: 36px;
            -webkit-appearance: none;
            background: url('../img/select-bg.png') no-repeat;
            background-position: right 15px center;
            background-size: 8px;
            -webkit-font-smoothing: antialiased;
          }
        }
      }
      .btn-custom {
        display: inline-block;
        height: 35px;
        padding: 10px 20px;
        line-height: 16px;
        background: #DCE84C;
        box-shadow: 0 1px 0 0 #A9B811;
        border-radius: 100px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #143C96;
        span {
          display: inline-block;
          vertical-align: middle;
        }
        span.icon {
          color: #143C96;
          font-size: 8px;
          font-weight: 700;
          margin-right: 3px;
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      text-align: right;
      padding: 30px 30px;
      .form-group {
        margin-bottom: 0px;
        input[type="submit"] {
          padding-left: 35px;
          padding-right: 35px;
        }
      }
    }
  }
  form {
    .form-group {
      label {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #4E5A61;
        margin-bottom: 10px;
      }
      input {
        background: none;
        background-color: #FFF;
        border: 2px solid #D3D9DB;
        height: 50px;
        border-radius: 2px;
        box-shadow: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;

        &::placeholder{
          color: #677889;
        }
      }
      .input-group-btn {
        .btn-default {
          height: 50px;
          border: 2px solid #EBEBEB;
          border-left-width: 0;
        }
      }
      textarea {
        background: none;
        background-color: #FFF;
        border: 2px solid #D3D9DB;
        height: 133px;
        border-radius: 2px;
        box-shadow: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;

        &::placeholder{
          color: #677889;
        }
      }
      select {
        background: none;
        background-color: #FFF;
        border: 2px solid #D3D9DB;
        height: 50px;
        border-radius: 2px;
        box-shadow: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;

        &::placeholder{
          color: #677889;
        }
      }
      input[type="submit"] {
        height: 50px;
        line-height: 50px;
        background: none;
        border: none;
        background-color: #DCE84C;
        box-shadow: 0 1px 0 0 #A9B811;
        border-radius: 100px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: #143C96;
        padding: 0px 100px;
        &:disabled {
          opacity: 0.7 !important;
        }
      }
    }
  }
  h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #4E5A61;
    line-height: 37px;
    margin: 0px;
    margin-bottom: 30px;
  }
  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #4E5A61;
    line-height: 34px;
    margin: 0px;
    margin-bottom: 30px;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    span.icon {
      font-size: 20px;
      color: #4E5A61;
      margin-right: 10px;
    }
    span.text {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #4E5A61;
      -webkit-font-smoothing: antialiased;
    }
  }
  h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #4E5A61;
    line-height: 26px;
    margin: 0px;
  }
  ol {
    list-style: lower-roman;
    padding-left: 30px;
  }
  p {
    a {
      color: #143C96;
      text-decoration: underline;
    }
  }
  p, li {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #4E5A61;
    line-height: 24px;
    margin: 0px;
    margin-bottom: 30px;
    span {
      vertical-align: middle;
      display: inline-block;
      font-weight: 700;
    }
    span.icon {
      height: 48px;
      width: 48px;
      border-radius: 48px;
      background-color: #DCE84C;
      border: 1px solid #B5C420;
      line-height: 46px;
      text-align: center;
      margin-right: 10px;
      color: #143C96;
      font-size: 17px;
    }
    span.text {
      text-decoration: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: #143C96;
    }
    span.green {
      font-weight: 400;
      color: #A9B811;
    }
    a {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;
    }
    a.underline {
      text-decoration: underline;
    }
    a.green {
      color: #A9B811;
    }
  }
  p.item {
    margin-bottom: 10px;
  }
  #sidebar.nav.nav-pills.nav-stacked {
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    margin-bottom: 30px;
    li {
      margin-top: 0px;
      a {
        border-radius: 0px;
        background: none;
        background-color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-size: 16px;
        color: #4E5A61;
        line-height: 20px;
        padding: 20px;
        -webkit-font-smoothing: antialiased;
        span {
          font-family: 'Roboto', sans-serif !important;
          font-size: 14px;
          color: #4E5A61;
          -webkit-font-smoothing: antialiased;
        }
      }
      a:hover {
        background: none;
        background-color: #F6FAFB;
      }
      a:focus {
        background: none;
      }
    }
    li:hover {
      background-color: #F6FAFB;
    }
    li {
      background-color: #F6FAFB;
      a.active {
        font-weight: 700;
        background: none;
        background-color: #F6FAFB;
      }
      a.active:hover {
        background: none;
        background-color: #F6FAFB;
      }
      a.active:focus {
        background: none;
        background-color: #F6FAFB;
      }
    }
    li.bottom {
      border-top: 1px solid #EBEBEB;
    }
  }
  #sidebar.nav.nav-pills.nav-stacked.icons {
    li a {
      padding-left: 60px;
    }
    li a:before {
      position: absolute;
      top: 0px;
      left: 20px;
      bottom: 0px;
      width: 20px;
      font-family: 'icomoon';
      text-align: center;
      line-height: 60px;
      font-size: 16px;
      color: #143C96;
    }
  }
  .news {
    .nav.nav-pills {
      border: none;
      border-radius: 2px;
      margin-bottom: 30px;
      li {
        border-right: 1px solid #E7E7E7;
        margin-top: 0px;
        a {
          border-radius: 0px;
          background: none;
          background-color: #FFF;
          font-family: 'Roboto', sans-serif;
          font-weight: normal;
          font-size: 16px;
          color: #4E5A61;
          line-height: 20px;
          padding: 20px;
          span {
            display: block;
          }
          span.date {
            font-family: 'Roboto', sans-serif !important;
            font-weight: 300;
            font-size: 14px;
            color: #4E5A61;
            line-height: 24px;
          }
          span.title {
            font-family: 'Roboto', sans-serif !important;
            font-weight: 400;
            font-size: 18px;
            color: #A9B811;
            line-height: 24px;
          }
        }
        a:hover {
          background: none;
          background-color: transparent;
        }
        a:focus {
          background: none;
        }
      }
      li:hover {
        background-color: transparent;
      }
      li.active {
        position: relative;
        a {
          background: none;
          background-color: transparent;
        }
        a:hover {
          background: none;
          background-color: transparent;
        }
        a:focus {
          background: none;
          background-color: transparent;
        }
      }
      li.active:after, li:before {
        right: -1px;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      li.active:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #FFF;
        border-width: 7px;
        margin-top: -7px;
      }
      li.active:before {
        border-color: rgba(231, 231, 231, 0);
        border-right-color: #E7E7E7;
        border-width: 8px;
        margin-top: -8px;
      }
    }
  }
  .timeline {
    .item {
      position: relative;
      padding-bottom: 15px;
      padding-left: 25px;
      span.point {
        position: absolute;
        top: 8px;
        left: 0px;
        background-color: #E7E7E7;
        height: 8px;
        width: 8px;
        border-radius: 8px;
        display: block;
      }
      span.line {
        display: block;
        position: absolute;
        left: 3px;
        top: 0px;
        bottom: 0px;
        background-color: #E7E7E7;
        width: 2px;
      }
      span.date {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        font-size: 16px;
        color: #A9B811;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
      }
      ul {
        padding: 0px;
        padding-left: 20px;
        margin: 0px;
        li {
          list-style: initial;
          font-family: 'Roboto', sans-serif !important;
          font-weight: 400;
          font-size: 16px;
          color: #4E5A61;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
        }
      }
    }
    .item.active {
      span.point {
        background-color: #A9B811;
      }
    }
    .item:first-child {
      span.line {
        top: 10px;
      }
    }
    .item:last-child {
      span.hline {
        display: block;
        position: absolute;
        left: 0px;
        bottom: 0px;
        background-color: #E7E7E7;
        width: 8px;
        height: 2px;
      }
    }
  }
  .calendar {
    padding: 35px;
    .calendar-item {
      padding: 25px;
      border: 1px solid #EBEBEB;
      border-radius: 2px;
      span {
        display: block;
      }
      span.date {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        font-size: 13px;
        color: #4E5A61;
        line-height: 18px;
        -webkit-font-smoothing: antialiased;
      }
      span.title {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 400;
        font-size: 16px;
        color: #4E5A61;
        line-height: 21px;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 10px;
      }
      ul {
        margin: 0px;
        padding: 0px;
        li {
          position: relative;
          padding-left: 24px;
          line-height: 24px;
          font-family: 'Roboto', sans-serif !important;
          font-weight: 400;
          font-size: 16px;
          color: #4E5A61;
        }
        li.green {
          color: #A9B811;
        }
        li:before {
          position: absolute;
          top: 0px;
          left: -3px;
          width: 24px;
          font-family: 'icomoon';
          text-align: center;
          line-height: 24px;
          font-size: 16px;
          color: #A9B811;
        }
      }
    }
    .owl-carousel {
      .owl-stage {
        margin: 0px auto;
      }
      margin-top: 0px;
      margin-bottom: 0px;
      .owl-nav {
        position: absolute;
        top: 50%;
        margin-top: -30px;
        left: 0;
        right: 0;
        .owl-prev {
          display: inline-block;
          margin-left: -35px;
          span {
            font-size: 30px;
            color: rgba(136, 136, 136, 0.5);
          }
        }
        .owl-prev.disable {

        }
        .owl-next {
          float: right;
          display: inline-block;
          margin-right: -35px;
          span {
            font-size: 30px;
            color: rgba(136, 136, 136, 0.5);
          }
        }
        .owl-next.disable {

        }
      }
      .owl-dots {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        .owl-dot {
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px;
          span {
            display: inline-block;
            background-color: #EBEBEB;
            border: 1px solid transparent;
            height: 10px;
            width: 10px;
            border-radius: 10px;
          }
        }
        .owl-dot.active {
          span {
            background-color: #A9B811;
          }
        }
      }
    }
  }
}

section.products {
  padding-top: 20px;
  padding-bottom: 20px;
  .grid {
    text-align: left;
    .product-item {
      margin-bottom: 30px;
      border: 1px solid #eee;

      .photo {
        position: relative;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;

        img {
          margin: 0px auto;
          width: 100%;
        }
        .options {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.5);
          .content {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            padding: 10px;
          }
          p {
            margin-bottom: 5px;
            a {
              display: block;
              height: 38px;
              line-height: 34px;
              font-family: 'Roboto', sans-serif;
              font-weight: 700;
              font-size: 14px;
              color: #FFF;
              background-color: #B5C420;
              border: 1px solid #A9B811;
              border-radius: 100px;
              text-align: center;
              -webkit-font-smoothing: antialiased;
              span {
                display: inline-block;
                vertical-align: middle;
              }
              span.icon {
                font-size: 14px;
                margin-right: 5px;
              }
            }
            a.b {
              color: #B5C420;
              background-color: #FFF;
            }
          }
          p:last-child {
            margin-bottom: 0px;
          }
        }
      }
      .info {
        text-align: center;

        .quantity-input {
          margin-bottom: 5px;

          input[type=number] {
            height: 35px !important;
            width: 100%;
          }

          .quantity-input__control {
            line-height: 30px;
          }
        }

        .product__price {
          margin-bottom: 13px;
        }

        .btn-custom {
          margin-left: 0;
        }

        h6 {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #4E5A61;
          margin: 13px 0px;
          line-height: 21px;
        }
        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #4E5A61;
          //line-height: 26px;
          text-align: center;
          span {
            display: inline-block;
          }
          span.cond {
            margin-left: 5px;
          }
          span.price {
            font-size: 20px;
          }
          span.price.used {
            color: #143C96;
          }
          span.price.new {
            color: #A9B811;
          }
          span.discount {
            display: none;
            font-size: 14px;
            font-weight: bold;
            color: #1B8816;
          }
        }
        .ova {
          display: none;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 13px;
          color: #4E5A61;
          line-height: 18px;
          text-align: center;
          strong {
            font-weight: 700;
          }
        }
      }
    }
    .product-item:hover {
      .photo {
        .options {
          display: block;
        }
      }
    }
  }
  .filters {
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    .title {
      padding: 20px 20px;
      border-bottom: 1px solid #EBEBEB;
      h6 {
        margin: 0px;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        font-size: 14px;
        color: #4E5A61;
        line-height: 19px;
        -webkit-font-smoothing: antialiased;
      }
    }
    .title.dark {
      border-bottom: none;
      background-color: #E9EEEF;
      h6 {
        color: #4E5A61;
      }
    }
    .subtitle {
      padding: 10px 20px;
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      h6 {
        margin: 0px;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        font-size: 14px;
        color: #4E5A61;
        line-height: 19px;
        -webkit-font-smoothing: antialiased;
      }
    }
    .wrap {
      padding: 20px 0px;
    }
    .item {
      padding: 7px 20px;
      a {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 400;
        font-size: 13px;
        color: #4E5A61;
        line-height: 18px;
        cursor: pointer;
      }
      .custom-checkbox {
        margin-bottom: 0px;
      }
    }
    .item.active {
      a {
        color: #A9B811;
      }
    }
  }
}

section.product-details {
  padding-top: 20px;
  padding-bottom: 20px;
  .wrapper {
    margin: 0px;
    border-radius: 2px;
    border: 1px solid #EBEBEB;
    .left {
      padding: 0px;
      .photo {
        //padding: 30px;
        img {
          width: 100%;
        }
      }
    }
    .right {
      border-left: 1px solid #EBEBEB;
      padding: 0px;
      .top {
        padding: 30px;

        form {
          .form-group {
            .form-group__quantity {
              display: inline-block;
            }

            .notify-me-when-available {
              button.notify-me-when-available__button {
                margin-left: 0;
              }
            }
          }
        }

        span.hline {
          display: block;
          width: 36px;
          border-bottom: 1px solid #EBEBEB;
          margin: 20px 0px;
        }
        span {
          display: inline-block;
        }
        .title {
          font-family: 'Roboto', sans-serif !important;
          font-weight: 700;
          font-size: 28px;
          color: #4E5A61;
          vertical-align: middle;
          line-height: 37px;
          width: 80%;
        }
        .title__artist {
          font-weight: 700;
          font-size:14px;
          line-height:14px;
          color: #888888;
          display: block;
        }
        .release-date {
          color: $red;
          font-weight: bold;
        }
        .stock {
          width: 20%;
          float: right;
          text-align: right;
          &.without-stock {
            width: 31%;
          }
          span.icon {
            color: #A9B811;
            margin-right: 5px;
            font-size: 20px;
            vertical-align: middle;
            line-height: 37px;
          }
          span.text {
            float: right;
            font-family: 'Roboto', sans-serif !important;
            font-weight: 700;
            font-size: 14px;
            color: #A9B811;
            vertical-align: middle;
            line-height: 37px;
          }
        }
        p {
          font-family: 'Roboto', sans-serif !important;
          font-weight: 400;
          font-size: 16px;
          color: #4E5A61;
        }
      }
      .bottom {
        padding: 30px;
        ul.nav.nav-tabs {
          border-bottom: 1px solid #EBEBEB;
          li {
            margin: 0px 10px;
            div {
              background: none;
              background-color: #FFF;
              border: none;
              border-bottom: 4px solid #FFF;
              font-family: 'Roboto', sans-serif !important;
              font-weight: 700;
              font-size: 14px;
              color: #4E5A61;
              line-height: 19px;
              padding: 10px 0px;
              cursor: pointer;

              &:active,
              &:focus,
              &:hover {
                box-shadow: none;
                outline: none;
              }
            }
          }
          li.active {
            div {
              color: #4E5A61;
              border-bottom-color: #143C96;
            }
          }
          li:first-child {
            margin-left: 0px;
          }
        }
        .tab-content {
          .tab-pane {
            padding-top: 30px;
            p {
              font-family: 'Roboto', sans-serif !important;
              font-weight: 400;
              font-size: 14px;
              color: #4E5A61;
              line-height: 24px;
              margin-bottom: 20px;
            }
            table {
              border-radius: 2px;
              tr {
                th {
                  font-family: 'Roboto', sans-serif !important;
                  font-weight: 700;
                  font-size: 14px;
                  color: #4E5A61;
                  line-height: 19px;
                  background-color: #F6FAFB;
                  padding: 15px 20px;
                }
                td {
                  font-family: 'Roboto', sans-serif !important;
                  font-weight: 400;
                  font-size: 14px;
                  color: #4E5A61;
                  line-height: 19px;
                  padding: 15px 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-checkbox {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  label {
    cursor: pointer;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 2px;
    left: 0;
    background: #FFF;
    border: 1px solid #EBEBEB;
  }
  label:after {
    opacity: 0.0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 3px;
    left: 2px;
    border: 3px solid #143C96;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
  label:hover::after {
    opacity: 0.0;
  }
  span.text {
    display: inline-block;
    margin-left: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #4E5A61;
  }
  input[type=checkbox] {
    margin: 0px;
  }
  input[type=checkbox]:checked + label:after {
    opacity: 1;
  }
}

form {
  .help-block {
    color: red;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
  .form-group {
    label {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #4E5A61;
      margin-bottom: 10px;
      span.required {
        color: #AE1A20;
      }
    }
    input.form-control {
      background: none;
      background-color: #FFF;
      border: 2px solid #D3D9DB;
      height: 50px;
      border-radius: 2px;
      box-shadow: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #4E5A61;
    }
    input.form-control::placeholder {
      color: #677889;
    }
    textarea {
      background: none;
      background-color: #FFF;
      border: 2px solid #EBEBEB;
      height: 133px;
      border-radius: 2px;
      box-shadow: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #999;
      resize: none;
    }
    select {
      background: none;
      background-color: #FFF;
      border: 2px solid #EBEBEB;
      height: 50px;
      border-radius: 2px;
      box-shadow: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #999;
    }
    input[type="submit"] {
      height: 50px;
      line-height: 50px;
      background: none;
      border: none;
      background-color: #DCE84C;
      box-shadow: 0 1px 0 0 #A9B811;
      border-radius: 100px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #143C96;
      padding: 0 100px;
      -webkit-font-smoothing: antialiased;
      &:disabled {
        opacity: 0.7 !important;
      }
    }
  }
  .radio {
    margin-top: 15px;
    margin-bottom: 15px;
    input[type="radio"] {
      position: relative;
      margin: 0px;
      margin-left: -20px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
    }
    label {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #4E5A61;
      line-height: 27px;
      -webkit-font-smoothing: antialiased;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      span.bold {
        font-weight: 700;
      }
      div.exp-date {
        display: block;
        vertical-align: middle;
        margin-top: 5px;
        span {

        }
      }
      span.icon {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        height: 27px;
        width: 41px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      span.paypal {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        height: 18px;
        width: 68px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/paypal-bg.png');
      }
      span.icon.visa {
        background-image: url('../img/credit-card.png');
      }
      span.icon.amex {
        background-image: url('../img/credit-card-amex.png');
      }
      span.icon.mastercard {
        background-image: url('../img/credit-card-mastercard.png');
      }
      span.icon.discover {
        background-image: url('../img/credit-card-discover.png');
      }
      span.price {
        float: right;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: #4E5A61;
      }
      a {
        float: right;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: #A9B811;
      }
    }
  }
}

.radio {
  margin-top: 15px;
  margin-bottom: 15px;
  input[type="radio"] {
    position: relative;
    margin: 0px;
    margin-left: -20px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5A61;
    line-height: 27px;
    -webkit-font-smoothing: antialiased;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    span.bold {
      font-weight: 700;
    }
    div.exp-date {
      display: block;
      vertical-align: middle;
      span {

      }
    }
    span.icon {
      border: none;
      border-radius: 0px;
      background-color: transparent;
      height: 27px;
      width: 41px;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    span.paypal {
      border: none;
      border-radius: 0px;
      background-color: transparent;
      height: 18px;
      width: 68px;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('../img/paypal-bg.png');
    }
    span.icon.visa {
      background-image: url('../img/credit-card.png');
    }
    span.icon.amex {
      background-image: url('../img/credit-card-amex.png');
    }
    span.icon.mastercard {
      background-image: url('../img/credit-card-mastercard.png');
    }
    span.icon.discover {
      background-image: url('../img/credit-card-discover.png');
    }
    span.price {
      float: right;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #4E5A61;
    }
    a {
      float: right;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #143C96;
    }
  }
}

.login {
  background-color: #C9D0E0;
  background-image: url('../img/body-bg.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
}

.login-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100vh;
  .lightbox {
    width: 100%;
    .wrapper {
      width: 100%;
      padding: 40px 90px;
      margin-top: 30px;
      background-color: #FFF;
      border-radius: 2px;
      border: 1px solid #EBEBEB;
    }
    .brand {
      text-align: center;
      span.logo {
        display: block;
        width: 195px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url('../img/logo.png');
        margin: 0px auto;
      }
    }
    span.login-bg {
      display: block;
      width: 390px;
      height: 494px;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
      background-image: url('../img/login-bg@2x.png');
      margin: 0px auto;
    }
    span.forgot-bg {
      display: block;
      width: 361px;
      height: 481px;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
      background-image: url('../img/forgot-bg@2x.png');
      margin: 0px auto;
    }
    h1 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 36px;
      color: #4E5A61;
      line-height: 48px;
      margin: 0px;
      margin-bottom: 30px;
      -webkit-font-smoothing: antialiased;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;
      line-height: 24px;
      margin: 0px;
      margin-bottom: 30px;
      -webkit-font-smoothing: antialiased;
      a {

      }
      span {
        vertical-align: middle;
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #4E5A61;
        -webkit-font-smoothing: antialiased;
      }
      span.vline {
        height: 24px;
        border-right: 1px solid #EBEBEB;
        margin: 0px 20px;
      }
      span.green {
        color: #A9B811;
        font-weight: 700;
      }
    }
    p:last-child {
      margin-bottom: 0px;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5),
screen and (-moz-min-device-pixel-ratio: 1.5),
screen and (min-device-pixel-ratio: 1.5) {
  .login-wrapper {
    .lightbox {
      .brand {
        span.logo {
          background-image: url('../img/logo@2x.png');
        }
      }
    }
  }
  body header .navbar.top .navbar-header .navbar-brand span.logo {
    background-image: url("../img/logo@2x.png");
  }
  body {
    section#newsletter {
      background-image: url('../img/news-bg@2x.jpg');
    }
    section#categories {
      .grid {
        .item {
          div.icon {
            span.movies {
              background-image: url('../img/movies-bg@2x.png');
            }
            span.games {
              background-image: url('../img/games-bg@2x.png');
            }
            span.cable {
              background-image: url('../img/cable-bg@2x.png');
            }
            span.toys {
              background-image: url('../img/toys-bg@2x.png');
            }
            span.posters {
              background-image: url('../img/posters-bg@2x.png');
            }
          }
        }
      }
    }
    footer {
      .top {
        span.logo {
          background-image: url("../img/logo@2x.png");
        }
      }
    }
  }
}

ul[aria-labelledby="header-cart-dropdown"] {
  min-width: 350px;
  .header-cart {
    padding-top: 0px;
    .cart-item {
      margin-bottom: 0px;
      padding: 15px;
    }
  }
  li {
    a, a:hover {
      text-align: left !important;
      padding: 0px !important;
      background-color: transparent !important;
    }
  }
  .item {
    border-bottom: 2px solid #E8E8E8;
    padding: 15px;
    position: relative;
    .photo {
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      width: 62px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      img {
        width: 100%;
        vertical-align: middle;
      }
    }
    .remove-item {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -10px;
      a, a:hover {
        background-color: transparent;
        padding: 0px !important;
      }
      span {
        font-size: 20px;
        color: #C6CACC;
      }
    }
    .info {
      display: inline-block;
      vertical-align: middle;
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        margin-bottom: 0px;
        line-height: 24px;
        span {
          display: inline-block;
          vertical-aling: middle;
        }
        span.qty {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 13px;
          color: #4E5A61;
          margin-right: 20px;
        }
        span.price-label {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #4E5A61;
          margin-right: 5px;
        }
        span.price {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 18px;
          color: #143C96;
        }
        span.price.green {
          color: #A9B811;
        }
      }
      p:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .item:hover {
    background-color: #F6FAFB;
  }
  .subtotal {
    padding: 15px;
    border-bottom: 2px solid #E8E8E8;
    p {
      margin: 0px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 13px;
      color: #A9B811;
      line-height: 18px;
      span.right {
        float: right;
        color: #4E5A61;
      }
    }
  }
  .options {
    padding: 15px;
    p {
      text-align: center;
      margin: 0px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 14px;
      color: #888;
      line-height: 19px;
      a, a:hover {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #888;
        text-decoration: none;
      }
      span {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #888;
        display: inline-block;
        vertical-align: middle;
      }
      span.vline {
        margin: 0px 30px;
        border-right: 1px solid #EBEBEB !important;
        height: 22px !important;
      }
      span.green {
        color: #A9B811;
      }
      span.blue{
        color: #143C96;
      }
      span.gray{
        color: #4E5A61;
      }
    }
  }
}

.redux-toastr {
  .toastr.rrt-success {
    background: none;
    //background-color: #FFF;
    //background-color: #5367FA;
    background-image: linear-gradient(-46deg, #143C96 0%, #5367FA 100%);
    //border: 1px solid #EBEBEB;
    border: 1px solid #143C96;
    border-radius: 2px;
    box-shadow: none;
    //color: #4E5A61;
    color: #FFF;
    min-height: 0px;
    .rrt-left-container {
      position: relative;
      float: left;
      padding: 10px;
      padding-left: 20px;
      width: auto;
      .rrt-holder {
        position: relative;
        top: auto;
        left: auto;
        height: auto;
        width: auto;
        margin: 0px;
        line-height: 60px;
        span {
          display: inline-block;
          vertical-align: middle;
          color: #DCE84C;
          //color: #FFF;
          font-size: 20px;
        }
      }
    }
    .rrt-middle-container {
      padding: 10px;
      padding-left: 0px;
      height: auto;
      min-height: 0px;
      margin: 0px;
      .content {
        .col {
          .img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 60px;
            height: 60px;
            border-radius: 2px;
            background: transparent center center no-repeat;
            background-size: auto 100%;
          }
          h6 {
            display: inline-block;
            vertical-align: middle;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 16px;
            //color: #4E5A61;
            width: 60%;
            color: #FFF;
            line-height: 21px;
            text-align: left;
            margin: 0px;
          }
        }
      }
    }
    .rrt-right-container {
      position: relative;
      padding: 10px;
      padding-right: 20px;
      width: auto;
      float: right;
      line-height: 60px;
      button.close-toastr {
        position: relative;
        top: auto;
        right: auto;
        //color: #4E5A61;
        color: #FFF;
        padding: 0px;
        display: block;
        margin: 0px;
      }
    }
    &:hover {
      box-shadow: none;
    }
  }
}

// Needed to do this shit here due to a problem with all <a> without href
a {
  cursor: pointer !important;
}

.product-actions-buttons {
  margin-top: 15px;
}
